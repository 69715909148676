
/*
body {
	margin:0px;
	font-family:'Source Sans Pro', 'Open Sans',sans-serif;
	font-size:14px;
	line-height:1.4em;
}
*/

/*
.navbar-brand { padding:8px 12px 0 10px; margin:0; }
button, input, select, textarea, p, div { font-family:'Open Sans',sans-serif; font-weight:300; }
.red { color:#e01010; }
.orange { color:#e09010; }
.green { color:#50a010; }
.blue { color:#1050e0; }
.light { font-weight:300; }
.regular { font-weight:400; }
.semibold { font-weight:600; }
.bold { font-weight:700; }
.heavy { font-weight:900; }
h1, h2, h3 { font-family:'Open Sans',sans-serif; font-weight:300; margin:0; padding:0 0 15px 0; }
p { margin:0; padding:0 0 7px 0; }
b { font-weight:600; }
*/

.clear { clear:both; }

#area-project { }
#area-app { display:none; }

#area-app label,
#area-app label input { cursor:pointer; }
#area-app .label { font-size:11px; padding:2px 5px; font-weight:600; }
.form-control.width-auto,
#area-app .width-auto { width:auto; display:inline-block; }
#area-app .form-control.inline { display:inline-block; }

.icon-select { display:inline-block; width:10px; height:10px; border:1px dashed #111; }

/**
* BODY
*/

/*#area-app { padding:20px;  }*/

#area-app .btn:active,
#area-app .btn:focus { outline:none; }


/**
* PANES & INTERFACE
*/

#la-new-floorplan-modal .modal-body .row { padding-bottom:7px; }

.upper-pane { }
.lower-pane {
	//position: absolute;
	//width: 100%;
	//bottom: 110px;
	//padding-top:10px;
}
.lower-pane .columns{
	padding: 0.5rem;
	background: #FFFFFF;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
}
.lower-pane .well { width: auto; }
.lower-pane .well p{ margin: 0; }
.lower-pane .well.disabled { opacity:0.5; }
.lower-pane .area-info-container .project-info { margin-bottom:0; }
.lower-pane h3 { padding:0 0 2px 0; margin:0; font-size:15px; font-weight:700; text-transform:uppercase; }

	.poly-list { max-height:50vh;overflow:auto; }
		.poly-list .panel { /* margin-right:10px; */ }
		.poly-list .panel-heading { padding:5px 10px; cursor:pointer; }
		.poly-list .panel-title { font-size:14px; }
			.poly-list .panel-title .poly-edit-link-text { display: inline-block; line-height: 2.1875rem; font-size: 1.125rem; font-weight: bold; }
			.poly-list .panel-title .poly-edit-link { display: inline-block; width: 100%; }
			.poly-list .panel-title .poly-delete-link { display: inline-block; width: 100%; }
			.poly-list .panel-title .poly-edit-box { display:none; }
			.poly-list .panel-title .poly-delete-box { display:none; }
				.poly-list .panel-title .poly-edit-box .form-control { width:100%; display:inline-block; vertical-align:top; margin-right:2px; }
				.poly-list .panel-title .poly-edit-box button { margin-right:2px; }
		.poly-list .panel-body { padding:0; }

		.poly-list .poly-point-list { padding-bottom:5px; 
	list-style: none;
	margin: 0;}
			.poly-list .poly-point-list .point { margin:0; padding:0; cursor:default; text-align:left; border:1px solid #DCDCDC; margin-bottom: 0.125rem; clear:both; line-height: 1.375rem;}
			.poly-list .poly-point-list .point-info { display:inline-block; vertical-align:top; 
		white-space: nowrap;
	    line-height: 1.375rem;
		font-size: 0.75rem;}
			.poly-list .poly-point-list a { vertical-align:top; }
			.poly-list .area-label { display:inline-block; }
			.poly-list .area-value { display:inline-block; float: right; }

#action-history.well { padding:5px 10px; }

#message-popup { border:1px solid #ccc; background-color:#fff; padding:15px; position:absolute; box-shadow:1px 1px 2px rgba(0,0,0,0.3); z-index:1099; font-size:22px; font-weight:300; margin-top:-100px; display:none; width:415px; }
$type-primary: #7266BA;
$type-alert: #F05050;
$type-success: #27C24C;
$type-info: #23B7E5;
$type-warning: #E0BA11;
#message-popup.type-primary { color: $type-primary; }
#message-popup.type-danger,
#message-popup.type-error { color: $type-alert; }
#message-popup.type-success { color: $type-success; }
#message-popup.type-info { color: $type-info; }
#message-popup.type-warning { color: $type-warning; }
	#message-popup .img { float:left; width:80px; font-size:50px; padding:1px 0 0 5px; }
	#message-popup .text { float:left; width:300px; }
	#message-popup .text.small { font-size:17px; }
	#message-popup .close-btn { position:absolute; top:0; right:8px; width:10px; height:10px; text-align:center; font-size:15px; font-weight:400; color:#aaa; cursor:pointer; }

	#message-popup .arrow { background-color:#fff; border-right:1px solid #ccc; border-bottom:1px solid #ccc; width:30px; height:30px; position:absolute; bottom:-15px; box-shadow:2px 2px 1px rgba(0,0,0,0.1); transform: rotate(45deg); margin-left:175px; }
	#message-popup.point-left .arrow { border-left:1px solid #ccc; border-bottom:1px solid #ccc; border-right:none; width:30px; height:30px; position:absolute; bottom:auto; top:35px; box-shadow:-1px 0px 1px rgba(0,0,0,0.1); transform: rotate(45deg); margin-left:-30px; }
	#message-popup.point-top .arrow { background-color:#fff; border-left:1px solid #ccc; border-top:1px solid #ccc; border-right:none; border-bottom:none; width:30px; height:30px; position:absolute; bottom:auto; top:-15px; box-shadow:-1px -1px 1px rgba(0,0,0,0.1); transform: rotate(45deg); margin-left:175px; }
	#message-popup.point-right .arrow { border-right:1px solid #ccc; border-top:1px solid #ccc; border-left:none; border-bottom:none; width:30px; height:30px; position:absolute; bottom:auto; top:35px; box-shadow:1px -1px 1px rgba(0,0,0,0.1); transform: rotate(45deg); margin-left:0px; right:-15px; }



/**
* TOOL SIDEBAR
*/

.buttons-column a.btn { min-width:55px; max-width:100%; }
.buttons-column a.btn-default.active { border-color:#999; }
.buttons-column p { margin:0; padding:5px 0 10px 0; font-size:13px; }


/**
* WORKSPACE
*/

.workspace-column { position:relative; }
#workspace-container { width: calc(85% - 5rem); height:100%; overflow:auto; border:1px solid #777; background-color:#f9f9f9;margin-left: 5rem; }
#workspace { left:0; top:0; position:relative; overflow:hidden; background-repeat:no-repeat; background-position:left top; background-size:100%; }
	#workspace .drawline { position:absolute; z-index:299; display:none; width:0; height:0; min-width:10px; min-height:10px; background-color:rgba(0,0,0,0.1); }
		#workspace .drawline canvas { width:100%; top:0; left:0; margin:0; position:absolute; z-index:201; min-width:10px; min-height:10px; }

	#workspace .drawline { vertical-align:top; }
		#workspace .drawline canvas { vertical-align:top; }

		#workspace .polygon { position:absolute; display:none; z-index:199; opacity:0.3; }
		#workspace .polygon.subtract { position:absolute; z-index:300; opacity:0.3; }
		#workspace .polygon.selected { z-index:209; opacity:0.8; }
			#workspace .polygon canvas { width:100%; }
			#workspace .point-overlay { border:1px solid #3300cc; opacity:0.8; background-color:#ddd; position:absolute; z-index:199; width:9px; height:9px; border-radius:50%; }
			#workspace .point-overlay.selected { z-index:309; background-color:#9C4DB0; }
			#workspace .point-overlay.hilite {
				z-index:309;
				background-color:#40DD5F;
				-webkit-box-shadow: 0px 0px 25px 5px #00ffea;
				-moz-box-shadow: 0px 0px 25px 5px #00ffea;
				box-shadow: 0px 0px 25px 5px #00ffea;
				&:before{
					content: "\f063";
					font-family: FontAwesome;
					font-weight: normal;
					font-style: normal;
					margin: 0;
					text-decoration: none;
					position: absolute;
					bottom: -1rem;
					left: -2.25rem;
					color: #000000;
					font-size: 5.5rem;
				}
				&:after{
					content: "\f063";
					font-family: FontAwesome;
					font-weight: normal;
					font-style: normal;
					margin: 0;
					text-decoration: none;
					position: absolute;
					bottom: -0.375rem;
					left: -2rem;
					font-size: 5rem;
					color: #e74c3c;
				}
			}

#workspace.ui-state-disabled,
#workspace.ui-selectable-disabled { opacity:1.0; outline:none; }

/*#workspace-cover { position:absolute; z-index:199; background-color:rgba(0,0,0,0.5); left:0; top:0; display:none; }*/

body.scale-selecting .ui-selectable-helper { outline:none; border:none; }

/**
* CONTEXT MENUS
*/

.custom-context-menu { min-width:10px; font-size:12px; display:none; }
	.custom-context-menu > li > a { padding:3px 15px 3px 10px; }
	.custom-context-menu > li > a > .glyphicon { padding-right:3px; }


/**
* POPUPS
*/

/* #la-popup-modal .modal-dialog { max-width:350px; }
#la-popup-modal.size-small .modal-dialog { max-width:300px; }
#la-popup-modal.size-medium .modal-dialog { max-width:450px; }
#la-popup-modal.size-large .modal-dialog { max-width:550px; }
#la-popup-modal .modal-body .glyphicon { font-size:28px; vertical-align:middle; } */
#la-popup-modal .modal-footer{
	padding-top: 2rem;
}
#la-popup-modal .message-icon{
	.fa-question-circle{
		color: $type-warning;
	}
	.fa-info-circle{
		color: $type-info;
	}
	.fa-times-circle{
		color: $type-alert;
	}
	.fa-check-circle{
		color: $type-success;
	}
}
.button-default{
	margin: 0 !important;
	line-height: 35px;
	height: 35px;
	padding: 0 0.85rem;
}
.form-control{
	line-height: 35px;
	height: 35px;
	margin: 0 !important;
}
select.form-control{
	padding: 0 1.5rem 0 0.5rem;
}
input.form-control{
	padding: 0 0.5rem;
}


/**
* OPTIONS
*/

.scale-options { }
	.scale-options .btn.active { border-color:#ee5010; }
	.scale-options input.form-control { display:inline-block; width:80px; }
	.scale-options .extra-info { display:none; font-size:13px; padding:4px 0 0 1px; }
.ui-selectable-helper { /*border-color:#ccc;*/ /*border:none;*/ }



/**
* MEDIA QUERIES
*/

@media only screen and (max-width : 1656px) {

	.app-actions-container .goback-btn { margin-top:5px; }
	.lower-pane .well {  }
	//.zoom-options .zoom-spacer { display:block; height:6px; }

}

@media only screen and (max-width : 1400px) {

	.lower-pane .well {  }
	.poly-list .poly-point-list .point-info {  }
	.sidebar-buttons.panel-body { padding:10px; }
	.buttons-column a.btn { min-width:10px; padding:12px 12px; font-size:17px; }

}

@media only screen and (max-width : 1200px) {

	.lower-pane .well {  }
	.poly-list .poly-point-list .point-info { }
	.buttons-column a.btn { font-size:18px; padding:10px 15px; }
	.buttons-column a.btn.button-select { padding:7px 15px; }

	.sidebar-buttons.text-center { text-align:left; }
	.sidebar-buttons > p { display:inline-block; margin:0; padding:0 5px 5px 5px; text-align:center; }
	.sidebar-buttons p.notifier { display:inline-block; position:absolute; right:30px; top:32px; text-align:right; }
	.sidebar-buttons .break,
	.sidebar-buttons hr { display:none; }

}

@media only screen and (max-width : 992px) {
}

@media only screen and (max-width : 768px) {
}

@media only screen and (max-width : 767px) {

	.upper-pane { height: auto; }
		.upper-pane #workspace-container { height:auto; }

	.lower-pane { height: auto; }
		.lower-pane .well {  height:auto; }

		.poly-list { max-height: 1000px; }
			.poly-list .panel { margin-right:0px; }

}




/**
* DEBUG
#workspace { background-color:rgba(1,1,1,0.1); }
#workspace .polygon { background-color:rgba(200,20,01,0.4); }
#workspace .polygon.selected { background-color:rgba(20,200,01,0.4); }
#header { background-color:rgba(50,0,200,0.1); }
.upper-pane { background-color:rgba(200,50,0,0.1); }
.lower-pane { background-color:rgba(50,200,0,0.1); }
#workspace .drawline canvas { border:1px dashed red; }
#workspace .drawline { background-color:rgba(100,50,10,0.1); }
*/