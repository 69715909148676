$fa-font-path: "../fonts/";

$font-family-sans-serif: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$body-font-family: $font-family-sans-serif !default;

$foundation-palette: (
  primary: #3a3f51,
  secondary: #777,
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
);
//@import 'settings';
@import 'foundation';
//@import 'motion-ui';

@include foundation-everything;

//@include motion-ui-transitions;
//@include motion-ui-animations;

@import 'font-awesome';
@import 'nv.d3';
@import 'spectrum';

$menu-width: 3rem;
$menu-width-expanded: 10rem;
$menu-icon-size: $menu-width/2.5;
$menu-padding: 0;//0.46875rem;
$menu-spacing: 0.9375rem;

$menu-not-expanded: $menu-width + $menu-spacing;
$menu-is-expanded: $menu-width-expanded + $menu-spacing;
$footer-height: 4rem;

$body-bg: #f0f3f4;
$top-bg: #ffffff;
$heading-bg: #f6f8f8;
$menu-bg: #3a3f51;
$menu-active: #2e3344;
$login-bg: #ffffff;

body{
	background-color: $body-bg;
	z-index: 0;
}

h1{
	font-size: 2rem;
	line-height: 1;
}

.login{
	position: relative;
	background: $login-bg;
	padding: 0.9375rem 0;
	border-radius: 0.9375rem;
	&:before{
		display: block;
		position: absolute;
		left: -2rem;
		top: 3rem;
		border-color: transparent $login-bg transparent transparent;
		border-style: solid;
		border-width: 1rem;
		width: 0;
		height: 0;
	}
	.forgot{
		font-size: 0.75rem;
	}
}

.switch{
	margin-bottom: 0 !important;
	float: none;
	.switch-paddle{
		span{
			color: $white;
		}
	}
	& > label{
		cursor: pointer;
		& > span{
			line-height: 2rem;
			padding: 0 1rem;
			vertical-align: top;
		}
	}
}
.switch + label{
	line-height: rem-calc(24);
}

.content-left{
	position: fixed;
	top: 0;
	left: 0;
	padding: $menu-padding;
	height: 100%;
	width: $menu-width;
	background: $menu-bg;
	color: $white;
	transition: width ease 0.5s;
	h1{
		visibility: hidden;
		font-size: 1.375rem;
		font-weight: bold;
		line-height: 3rem;
		text-align: left;
		padding: 0 0.9375rem;
		body.expand &{
			visibility: visible;
		}
	}
	body.expand &{
		width: $menu-width-expanded;
	}
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		color: inherit;
		li{
			margin: 0;
			padding: 0;
			white-space: nowrap;
			color: inherit;
			overflow: hidden;
			a{
				display: inline-block;
				width: 100%;
				line-height: $menu-width/1.25;
				color: inherit;
				transition: background ease 0.5s;
				.icon{
					display: inline-block;
					font-size: $menu-icon-size;
					width: $menu-width - $menu-padding;
					text-align: center;
				}
				&.active{
					background: $menu-active;
				}
				&:hover{
					background: $black;
				}
			}
		}
	}
}
.content-right-top{
	position: relative;
	margin-left: $menu-width;
	padding: 0 $menu-spacing;
	transition: margin-left ease .5s;
	line-height: 3rem;
	min-height: 3rem;
	background: $top-bg;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	z-index:2;
	body.expand &{
		margin-left: $menu-width-expanded;
	}
	.button-expand{
		padding: 0 0.9375rem;
	}
}
.content-right-heading{
	position: relative;
	margin-left: $menu-width;
	padding: 0 $menu-spacing;
	transition: margin-left ease .5s;
	min-height: 3rem;
	background: $heading-bg;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	z-index:1;
	h1{
		line-height: 1.75;
		margin: 0;
		.button{
			position: relative;
			top: -0.125rem;
			margin: 0;
		}
	}
	.input-group{
		line-height: 3.5rem;
		margin: 0;
		.input-group-label, .input-group-field, .input-group-button{
			vertical-align: baseline !important;
		}
		.input-group-field{
			position: relative;
			//top: 0.125rem;
		}
		.input-group-button{
			position: relative;
			top: -0.125rem;
		}
	}
	body.expand &{
		margin-left: $menu-width-expanded;
	}
	.button-expand{
		padding: 0 0.9375rem;
	}
}
.content-right{
	position: relative;
	margin-left: $menu-not-expanded;
	padding: $menu-spacing $menu-spacing $menu-spacing 0;
	transition: margin-left ease .5s;
	body.expand &{
		margin-left: $menu-is-expanded;
	}
	body.has-footer &{
		padding-bottom: $footer-height !important;
	}
}
.footer{
	position: fixed;
	bottom: 0;
	left: 0;
	//height: $footer-height;
	width: calc( 100% - #{$menu-not-expanded} );
	margin-left: $menu-not-expanded;
	padding: $menu-spacing $menu-spacing $menu-spacing 0;
	transition: margin-left ease .5s;
	body.expand &{
		margin-left: $menu-is-expanded;
		width: calc( 100% - #{$menu-is-expanded} );
	}
	.button{
		margin: 0;
	}
}

table{
	td{
		a:not(.button):not(.action){
			display: inline-block;
			width: 100%;
		}
	}
}

.actions{
	.button{
		margin: 0;
		padding: 0.375rem;
	}
}

textarea.line-area{
	height: 7.4375rem;
}

.map{
	width: 100%;
	height: 22.375rem;
	border: none;
}

.build{
	position: relative;
	width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    overflow: auto;
    &.grab{
		cursor: move;
		cursor: grab;
    	cursor: -moz-grab;
		cursor: -webkit-grab;
    }
    &.grabbing{
	    cursor: grabbing;
	    cursor: -moz-grabbing;
	    cursor: -webkit-grabbing;
    }
    #workspace-container{
    	background-color: #AAAAAA;
    }
    .options{
    	position: absolute;
    	top: 0.0625rem;
    	left: 0.0625rem;
    	width: 5rem;
    	padding: 1rem 0.75rem 0;
    	background: #FFFFFF;
    	border: 1px solid #AAAAAA;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		z-index: 999;
		.button{
			background: #FFFFFF;
			color: #333333;
			text-align: center;
			width: 100%;
			-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
			-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
			box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
			padding: 0.25rem;
			font-size: 0.9rem;
			&.success{
				background: #3adb76;
				color: #fefefe;
			}
			&.active{
				background: #3a3f51;
				color: #FFFFFF;
			}
			i{
				margin-bottom: 0.25rem;
			}
		}
		hr{
			margin-top: 0;
			margin-bottom: 1rem;
		}
    }
    .right-options{
    	position: absolute;
    	top: 0.0625rem;
    	right: 0.0625rem;
    	width: 15%;
    	padding: 1rem 0.75rem 0;
    	background: #FFFFFF;
    	border: 1px solid #AAAAAA;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		z-index: 999;
		.button{
			text-align: center;
			width: 100%;
			-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
			-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
			box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
			padding: 0;
			line-height: 2.0625rem;
			&.active{
				background: #3a3f51;
				color: #FFFFFF;
			}
			i{
				margin-bottom: 0.25rem;
			}
		}
		.areas{
			background: #E7E7E7;
			padding: 0.25rem;
			margin-bottom: 0.75rem;
			ol{
				margin-bottom: 0;
			}
		}
    }
    .footer-options{
    	position: fixed;
    	width: 100%;
    	left: 0;
    	bottom: 0;
    	z-index: 999;
	    /* .zoom-options{
	    	position: absolute;
	    	z-index: 1000;
	    	//bottom: 0.0625rem;
	    	left: 0.0625rem;
	    }
	    .scale-options{
	    	position: absolute;
	    	z-index: 1000;
	    	//bottom: 0.0625rem;
	    	right: 0.0625rem;
	    } */
	    .well{
		    padding: 1rem 0 0.75rem;
		    background: #FFFFFF;
    		border: 1px solid #AAAAAA;
		    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
		    z-index: 999;
	    }
	    .form-options{
	    	.button{
	    		margin: 1.25rem 0 0 0;
	    		vertical-align: middle;
	    	}
	    }
	    h3{
	    	font-size: 1rem;
	    	font-weight: bold;
	    }
	}
    .temp{
    	position:absolute;
    	top: 120px;
    	left: 215px;
    	width: 1200px;
    	height: 595px;
    	border: rgba(250,20,50,1);
    	background: rgba(250,20,50,0.5);
    	font-size: 3rem;
    	font-weight: bold;
    	color: #000000;
    	line-height: 595px;
    	text-align: center;
    }
}
#message-popup{
	.img{
		span{
			font-size: 4rem;
		}
	}
	.button{
		margin: 0;
	}
}
.aream2{
	display: inline-block;
	width: 100% !important;
	font-size: 0.9rem;
	color: #3a3f51;
	text-align: left;
	span{
		//width: 120px !important;
	}
}
.subtractionm2{
	display: inline-block;
	width: 100% !important;
	font-size: 0.9rem;
	color: #ec5840;
	text-align: left;
	span{
		//width: 120px !important;
	}
}
.heatedm2{
	display: inline-block;
	width: 100% !important;
	font-size: 0.9rem;
	color: #3adb76;
	text-align: left;
	span{
		//width: 120px !important;
	}
}

#dragndrop{
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
    padding: 1rem;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    position: fixed;
    text-align: center;
    display: none;
    /*pointer-events: none;*/
    & > *{
    	pointer-events: none;
    }
    & > div{
	    border: 2px dashed #F8F8F8;
	    width: 100%;
	    height: 100%;
    }
    p{
	    font-size: 1.625rem;
	    color: #F8F8F8;
	    text-align: center;
	    margin: 0;
    }
    .notice{
	    position: relative;
	    padding-top: 10%;
    }
}

.upload{
	position: relative;
	max-width: 8rem;
	margin: 0 0.5rem 0.5rem 0;
	padding: 0.25rem;
	border: 0.125rem solid $menu-bg;
	background: $white;
	text-align: center;
	.button{
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.300rem 0.375rem;
	}
	&.logo{
		width: 100%;
		max-width: 100%;
	}
}

.table-preview{
	max-height: 2.5rem;
}

.pagination{
	text-align: center;
	.current{
		background: none;
	}
	.unavailable{
		pointer-events: none;
		a{
			display: none;
		}
	}
}
.reveal{
	.button{
		margin: 0;
	}
	.close-reveal-modal{
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.5rem;
	}
}
.autocomplete{
	display: none;
	position: relative;
	z-index: 9999;
	.results{
		z-index: 10000;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0.4375rem 0.4375rem 0 0.4375rem;
		background: $white;
		border: 0.0625rem solid #DCDCDC;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
		.autofill{
			display: inline-block;
			width: 100%;
			padding: 0.5rem;
			margin: 0 0 0.4375rem 0;
			h2{
				font-size: 1.125rem;
				line-height: 1;
				margin: 0;
				padding: 0;
			}
		}
	}
}
.upload-form{
	background: $white;
	padding: 0.9375rem;
	margin-bottom: 0.9375rem;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	input{
		margin-bottom: 0;
	}
}
.workspace-column{
	position: relative;
}
#workspace{
	height: 100%;
	overflow: auto;
}
.custom-context-menu{
	position: fixed;
	background: $white;
	padding: 0;
	margin: 0;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	z-index: 999;
	li{
		list-style: none;
		padding: 0.25rem 0;
		&:hover{
			background: $menu-bg;
			a{
				color: $white;
			}
		}
	}
}
.form-table{
	select, input, textarea{
		margin: 0;
	}
	th, td{
		&:not(.text-right):not(.text-left){
			text-align: center;
		}
	}
	.input-group{
		margin: 0;
		.button{
			border: none;
			border-top: 1px solid transparent;
		}
	}
}
.disclaimer{
	//padding: 0.9375rem;
	textarea{
		margin: 0;
		border: 0.0625rem solid #333;
	}
}
.plan{
	position: relative;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
	margin-bottom: 1rem;
	width:100%;
	&.landscape{
		img:not(.printlogo){
			-webkit-transform-origin: top left;
			-moz-transform-origin: top left;
			-ms-transform-origin: top left;
			-o-transform-origin: top left;
			transform-origin: top left;
		    -ms-transform: rotate(90deg);
		    -webkit-transform: rotate(90deg);
		    transform: rotate(90deg);
		    display: block;
		    position:absolute;
		    top:0;
		    left:0;
		}
	}
	&:not(.landscape){
		img:not(.printlogo){
			width:100%;
		}
	}
	.overlay{
		position:absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		&.transparent{
			opacity: 0.75;
		}
	}
	canvas{
		position:absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		&.transparent{
			opacity: 0.75;
		}
	}
}
.poly-cable-edit-me{
	position: relative;
}
.poly-cable-edit-me input{
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.poly-cable-edit-me:after{
	content: "\f040";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	position: absolute;
	font-size: 1rem;
	color: #AAAAAA;
	top: 0;
	right: 2.25rem;
	z-index: 1;
}
.no-margin{
	margin: 0 !important;
}
.delete-point-button{
	display: inline-block;
	width: 1.25rem !important;
	line-height: 1.25rem !important;
	padding: 0 !important;
	float: right;
}
.poly-point-list{
	.point:hover{
		background: #E7E7E7;
	}
}
span.required{
	background: #990000;
	color: #FFFFFF;
	line-height: 1.5;
	padding: 0 0.5rem;
}
.action{
	display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.375rem 0.125rem;
    font-size: 1.1rem;
	color: #2199e8;
}